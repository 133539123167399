<template>
  <div id="transfer" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <form @submit.prevent="passes(initializeGraduate)">
          <div class="columns is-multiline">
            <div class="column is-12">
              <b-table
                :data="graduatingStudents"
                :checked-rows.sync="checkedStudents"
                checkable
                hoverable
              >
                <b-table-column
                  field="#"
                  label="#"
                  width="40"
                  numeric
                  v-slot="props"
                  >{{
                    graduatingStudents.indexOf(props.row) + 1
                  }}</b-table-column
                >

                <b-table-column label="RegId" v-slot="props">{{
                  props.row.regId.toUpperCase()
                }}</b-table-column>

                <b-table-column label="Name" v-slot="props">{{
                  props.row.name
                }}</b-table-column>

                <template slot="bottom-left">
                  <SdInput
                    type="text"
                    rules="required"
                    label="Total checked"
                    v-model="checkedStudents.length"
                    placeholder="Total checked"
                    readonly
                  />
                </template>
              </b-table>
            </div>
            <div class="column is-12">
              <button
                type="submit"
                class="button btn-120 is-primary is-capitalized is-pulled-right"
              >
                Graduate
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
    <!-- Delete -->
    <app-modal
      id="graduating_students"
      context="update"
      @update="submit"
      :show-modal.sync="isModalOpen"
    >
      <template slot="add">
        <p>You want to graduate these students?</p>
        <p>Proceed?</p>
      </template>
    </app-modal>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchTerm } from '@/assets/js/app_info.js'
import SdInput from '@/components/SdInput'
import SdSelect from '@/components/SdSelect'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '@/assets/js/notification'
import SCHOOL_CLASSES from '../../../graphql/school_class/SchoolClasses.gql'

export default {
  name: 'student',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Graduating Students',
      graduatingStudents: [],
      checkedStudents: [],
    }
  },
  methods: {
    initializeGraduate() {
      this.openModal()
    },
    submit() {
      const students = this.checkedStudents.map((student) => {
        return {
          student_id: student.id,
          term_id: student.termId,
          school_class_id: student.schoolClassId,
        }
      })

      // Add
      this.$apollo
        .mutate({
          mutation: gql`
            mutation CreateGraduatesQuery($students: JSON!) {
              createGraduates(input: { students: $students }) {
                errors
              }
            }
          `,
          // Parameters
          variables: {
            students: students,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.createGraduates.errors,
            'Successfully created.'
          ).then(() => {
            this.closeModal()
            this.$router.push(
              `/school/${this.$route.params.school_id}/graduates`
            )
          })
        })
        .catch((error) => {
          console.error(error.message)
        })
    },
  },
  components: {
    SdInput,
    ValidationObserver,
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.graduateClassGroupId = this.$route.params.id

    this.$store.commit('setPageTitle', this.pageTitle)

    this.$apollo.addSmartQuery('graduatingStudents', {
      query: gql`
        query GraduatingStudentsQuery($classGroupId: ID!) {
          graduatingStudents(classGroupId: $classGroupId) {
            id
            regId
            name
            termId
            schoolClassId
          }
        }
      `,
      variables: {
        classGroupId: parseInt(this.graduateClassGroupId),
      },
    })

    this.$apollo.queries.graduatingStudents.refetch()

    this.$store.commit('setSubMenus', [
      {
        name: 'Graduate Class Groups',
        route: `/school/${this.schoolId}/graduate_class_groups`,
      },
    ])
  },
}
</script>
